// components/PageHeader.js
import React from "react";

const PageHeader = () => (
  <div
    className="container-fluid page-header mb-5 p-0"
    style={{ backgroundImage: "url(/img/carousel-bg-1.jpg)" }}
  >
    <div className="container-fluid page-header-inner py-5">
      <div className="container text-center">
        <h1 className="display-3 text-white mb-3 animated slideInDown">
          Thông tin Khoa
        </h1>
        {/* <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center text-uppercase">
            <li className="breadcrumb-item">
              <a href="/#">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Giới thiệu</a>
            </li>
            <li
              className="breadcrumb-item text-white active bold"
              aria-current="page"
            >
              Thông tin Khoa
            </li>
          </ol>
        </nav> */}
      </div>
    </div>
  </div>
);

export default PageHeader;
