import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LogoNEU from "../LogoNEU.png";
import "./footer.css";
const FooterCurriculum = () => {
  return (
    <footer
      className="footerCurriculum py-4"
      style={{ backgroundColor: "#085ca7" }}
    >
      <div className="container h-100 text-light">
        <div className="row gap-5 mt-4 h-100">
          <div className="col-2 d-sm-flex d-none justify-content-center align-items-center">
            <img src={LogoNEU} alt="LogoNeu" width={120} height={120} />
          </div>

          <div className="col-8">
            <p className="h5 fw-bold text-white mt-sm-3">
              Phòng quản lý đào tạo
            </p>
            <p>
              Địa chỉ: Phòng 210 - 211 - 213 Tầng 2 - Nhà A1 - Trường ĐH Kinh tế
              Quốc dân
            </p>
            <p>207 Giải Phóng, Đồng Tâm, Quận Hai Bà Trưng, Hà Nội, Việt Nam</p>
            <p>Điện thoại: 0243 6280 280/ máy lẻ: 5222</p>
            <p>Email: phqldt@neu.edu.vn</p>
            <p>
              Mạng xã hội:{" "}
              <a href="https://facebook.com/tvtsneu" className="text-white">
                facebook.com/tvtsneu
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterCurriculum;
