// src/index.js
import "instantsearch.css/themes/algolia-min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap/dist/js/bootstrap";
import "./scss/main.scss"; // Import the main SCSS file

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
reportWebVitals();
