// components/IntroInfo.js
import React, {useEffect} from "react";
import useFetch from "../utils/fetch";
import config from "../utils/config";
import PageHeader from "../components/IntroInfo/PageHeader";
import IntroContent from "../components/IntroInfo/IntroContent";
import DetailSection from "../components/IntroInfo/DetailSection";
import Spinner from "../containers/Spinner";

const IntroInfo = () => {
  useEffect(()=>{
    document.title = "Giới thiệu | Khoa Công nghệ thông tin";
  },[]);
  const {
    data: infoData,
    error,
    loading,
  } = useFetch(`${config.API_URL}/api/info-page?populate=*`);

  if (loading) return <Spinner />;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const {
    generalIntroduction,
    missions,
    functions,
    visions,
    additionalInfo,
    thumbnail,
  } = infoData.data.attributes;

  return (
    <>
      <PageHeader />
      <IntroContent
        generalIntroduction={generalIntroduction}
        thumbnail={thumbnail.data.attributes.url}
      />
      <DetailSection
        missions={missions}
        functions={functions}
        visions={visions}
        additionalInfo={additionalInfo}
      />
    </>
  );
};

export default IntroInfo;
