import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/accordion";
import "../css/major.css";
import { useParams } from "react-router-dom";

const Major = () => {
  const { slug } = useParams(); // Use useParams to get the slug directly from the URL
  const [activeTab, setActiveTab] = useState("K66");
  const [majorData, setMajorData] = useState(null);

  useEffect(() => {
    $("#accordion").accordion({
      collapsible: true, // Allow for collapsing
      heightStyle: "content",
    });

    fetch(`https://fit.neu.edu.vn/admin/api/majors/slug/${slug}`)
      .then((res) => res.json())
      .then((res) => {
        setMajorData(res.data);
        $("#accordion").accordion("refresh");
        document.title = `${res.data?.attributes.title} | Khoa Công nghệ thông tin`;
      })
      .catch(() => {
        window.location.href = "404";
      })
      .finally(() => {
        const loader = document.querySelector("#spinner");
        if (loader) {
          loader.classList.remove("show");
        }
      });
  }, [slug]);

  // Function to handle tab change
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <main
      style={{
        width: "80%",
        margin: "auto",
        fontFamily: "'Barlow', sans-serif",
      }}
    >
      <h1 id="title" style={{ marginTop: "5%", color: "#0054a2" }}>
        {majorData?.attributes.title}
      </h1>
      <p id="admissionCode">
        <strong>Mã tuyển sinh: {majorData?.attributes.admissionCode}</strong>
      </p>
      <p id="enrollmentTarget">
        <strong>Chỉ tiêu: {majorData?.attributes.enrollmentTarget}</strong>
      </p>

      <div id="accordion">
        <h3 style={{ fontSize: "1.2rem" }}>Chương trình đào tạo</h3>
        <div>
          <div className="tab">
            <button
              className={`tablinks ${activeTab === "K66" ? "active" : ""}`}
              onClick={() => handleTabChange("K66")}
            >
              K66
            </button>
            {(slug === "cong-nghe-thong-tin" ||
              slug === "khoa-hoc-may-tinh") && (
              <>
                <button
                  className={`tablinks ${activeTab === "K63" ? "active" : ""}`}
                  onClick={() => handleTabChange("K63")}
                >
                  K63
                </button>
                <button
                  className={`tablinks ${activeTab === "K61" ? "active" : ""}`}
                  onClick={() => handleTabChange("K61")}
                >
                  K61
                </button>
              </>
            )}
          </div>

          <div
            id="K66"
            className="tabcontent"
            style={{ display: activeTab === "K66" ? "block" : "none" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: majorData?.attributes.syllabus66,
              }}
            ></div>
          </div>
          {(slug === "cong-nghe-thong-tin" || slug === "khoa-hoc-may-tinh") && (
            <>
              <div
                id="K63"
                className="tabcontent"
                style={{ display: activeTab === "K63" ? "block" : "none" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: majorData?.attributes.syllabus63,
                  }}
                ></div>
              </div>
              <div
                id="K61"
                className="tabcontent"
                style={{ display: activeTab === "K61" ? "block" : "none" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: majorData?.attributes.syllabus61,
                  }}
                ></div>
              </div>
            </>
          )}
        </div>
        <h3 style={{ fontSize: "1.2rem" }}>Mô tả</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: majorData?.attributes.introduction,
          }}
        ></div>
        <h3 style={{ fontSize: "1.2rem" }}>Chuẩn đầu ra</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: majorData?.attributes.graduateOutcomes,
          }}
        ></div>

        <h3 style={{ fontSize: "1.2rem" }}>Cơ hội nghề nghiệp</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: majorData?.attributes.careerOpportunities,
          }}
        ></div>
      </div>
    </main>
  );
};

export default Major;
