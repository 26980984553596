import { useEffect, useState } from "react";
import FooterCurriculum from "./footer/FooterCurriculum";
import HeaderCurriculum from "./header/HeaderCurriculum";
import { Outlet } from "react-router-dom";

function Curriculum() {
  const [dataSchool, setDataSchool] = useState([]);
  const [dataFalcuties, setDataFalcuties] = useState([]);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const [response, response1] = await Promise.all([
          fetch(
            "https://fit.neu.edu.vn/admin/api/curriculum-schools?populate=deep,3"
          ),
          fetch(
            "https://fit.neu.edu.vn/admin/api/curriculum-faculties?populate=deep,3"
          ),
        ]);
        const [res, res1] = await Promise.all([
          response.json(),
          response1.json(),
        ]);
        setDataSchool(res.data);
        setDataFalcuties(res1.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, []);

  return (
    <>
      <HeaderCurriculum
        dataSchools={dataSchool}
        dataFalcuties={dataFalcuties}
      />
      <Outlet context={{ data: dataSchool, dataFalcuties: dataFalcuties }} />
      <FooterCurriculum />
    </>
  );
}

export default Curriculum;
