import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "firebaseui/dist/firebaseui.css";
import config from "../config";
import { makeid } from "../utils/codelab";

const AddRoomModal = ({ show, handleClose, room, user }) => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) {
      fetch(`${config.API_BASE_URL}/labs/${room.docID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setRooms(data); // Assuming the API response is an array of rooms
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching rooms:", error);
          setLoading(false);
        });
    }
  }, [show]);
  const handleCreateRoom = () => {
    const roomData = {
      docID: room.docID,
      userID: user.uid,
      roomID: makeid(6),
      userEmail: user.email,
      config: room.config,
    };

    fetch(`${config.API_BASE_URL}/createRoom`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(roomData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        window.location.href = "/room/" + roomData.roomID;
      })
      .catch((error) => {
        console.error("Error creating room:", error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Các phòng đã tạo:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border spinner-border rooms-spinner"
              role="status"
            ></div>
          </div>
        ) : (
          <table className="table table-borderless" id="table-rooms">
            <tbody id="tbody-rooms">
              {rooms.map((room) => (
                <tr key={room.roomID} id={room.roomID}>
                  <td className="align-middle">{room.roomID}</td>
                  <td className="text-end align-middle">
                    <a
                      href={`/room/${room.roomID}`}
                      className="text-primary"
                      target="_blank"
                    >
                      Vào phòng
                    </a>
                  </td>
                  <td className="text-end align-middle">
                    <a
                      href="#"
                      className="bi bi-three-dots-vertical link-dark"
                      data-bs-toggle="dropdown"
                    ></a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        Xóa
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          className="btn btn-primary"
          id="add-room-button"
          onClick={handleCreateRoom}
        >
          Tạo phòng mới
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddRoomModal;
