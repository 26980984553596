// components/DetailSection.js
import React from "react";
import "../../css/intro.css"

const DetailSection = ({ missions, functions, visions, additionalInfo }) => (
  <div className="container-xxl marginDetail">
    <div className="container">
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="d-flex bg-light px-4 ttkhoa_hover">
            <div className="ps-3">
              <h3 className="mb-3 fw-bold color-custom">SỨ MỆNH</h3>
              <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: missions }} />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="d-flex bg-light px-4 ttkhoa_hover">
            <div className="ps-3">
              <h3 className="mb-3 fw-bold color-custom">CHỨC NĂNG</h3>
              <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: functions }} />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="d-flex bg-light px-4 ttkhoa_hover">
            <div className="ps-3">
              <h3 className="mb-3 fw-bold color-custom" >TẦM NHÌN</h3>
              <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: visions }} />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
          <div className="d-flex py-5">
            <div className="">
              <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: additionalInfo }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DetailSection;
