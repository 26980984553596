import React, { useEffect, useState } from "react";
import { Card, Row, Col, Anchor, Divider } from "antd";
import { useNavigate } from "react-router-dom";

function MajorDetails() {
  const { Link: AnchorLink } = Anchor;
  const [majorData, setMajorData] = useState(null);
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState("K66");

  useEffect(() => {
    fetch("https://fit.neu.edu.vn/admin/api/majors/slug/khoa-hoc-may-tinh")
      .then((res) => res.json())
      .then((res) => {
        setMajorData(res.data);
      });
  }, []);

  useEffect(() => {
    const handleLinkClick = (event) => {
      const target = event.target.closest("a");
      if (target && target.href.includes("/syllabus/")) {
        event.preventDefault();

        // Adjust the path to remove any extra prefix
        const correctPath = target.href.split("/syllabus").pop();
        const finalPath = `/syllabus${correctPath}?update=false`;
        navigate(finalPath);
      }
    };

    document.addEventListener("click", handleLinkClick);

    return () => {
      document.removeEventListener("click", handleLinkClick);
    };
  }, [navigate]);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const dropdownStyle = {
    fontSize: "13px",
    cursor: "pointer",
    border: "none",
    color: "#246499",
  };

  return (
    <div>
      <main
        style={{
          width: "80%",
          margin: "auto",
          fontFamily: "'Barlow', sans-serif",
          fontSize: "18px",
        }}
      >
        <h1
          id="title"
          style={{ marginTop: "5%", color: "#0054a2", textAlign: "center" }}
        >
          {majorData?.attributes.title} - {majorData?.attributes.admissionCode}
        </h1>
        <Divider style={{ borderColor: "#0077BB" }} />
        <Row gutter={16}>
          <Col xl={6} xs={0} className="anchorLink">
            <Anchor>
              <AnchorLink href="#overview" title="Tổng quan" />
              <AnchorLink href="#majordetail" title="Chương trình đào tạo" />
              <AnchorLink href="#outcome" title="Chuẩn đầu ra" />
              <AnchorLink href="#opportunity" title="Cơ hội nghề nghiệp" />
            </Anchor>
          </Col>

          {/* Main Content */}
          <Col xl={18} xs={24} className="mainContent">
            {/* Tổng quan */}
            <div style={{ textAlign: "right", marginBottom: "0.5rem" }}>
              <span>Phiên bản : </span>
              <select
                value={selectedYear}
                onChange={handleChange}
                style={dropdownStyle}
              >
                <option value="K66">K66</option>
                <option value="K63">K63</option>
                <option value="K61">K61</option>
              </select>
            </div>
            <Card title="Tổng quan" id="overview">
              <div
                dangerouslySetInnerHTML={{
                  __html: majorData?.attributes.introduction,
                }}
              ></div>
            </Card>
            <br />

            {/* Chương trình đào tạo */}
            <Card title="Chương trình đào tạo" id="majordetail">
              {/* <div className="custom-table-wrapper">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: majorData?.attributes.syllabus66,
                                    }}
                                ></div>
                            </div> */}
              <div
                id="K66"
                className="tabcontent"
                style={{ display: selectedYear === "K66" ? "block" : "none" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: majorData?.attributes.syllabus66,
                  }}
                ></div>
              </div>
              <div
                id="K63"
                className="tabcontent"
                style={{ display: selectedYear === "K63" ? "block" : "none" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: majorData?.attributes.syllabus63,
                  }}
                ></div>
              </div>
              <div
                id="K61"
                className="tabcontent"
                style={{ display: selectedYear === "K61" ? "block" : "none" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: majorData?.attributes.syllabus61,
                  }}
                ></div>
              </div>
            </Card>
            <br />

            {/* Chuẩn đầu ra */}
            <Card title="Chuẩn đầu ra" id="outcome">
              <div
                dangerouslySetInnerHTML={{
                  __html: majorData?.attributes.graduateOutcomes,
                }}
              ></div>
            </Card>

            <br />

            {/* Cơ hội nghề nghiệp */}
            <Card title="Cơ hội nghề nghiệp" id="opportunity">
              <div
                dangerouslySetInnerHTML={{
                  __html: majorData?.attributes.careerOpportunities,
                }}
              ></div>
            </Card>
            <br />
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default MajorDetails;
