// Syllabus.js
import React from "react";
import { useLocation } from "react-router-dom";
import Document from "./Document";
const Syllabus = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get("display") || "doc"; //doc, book, slide
  const containerClass = display === "slide" ? "container-fluid" : "container";

  return (
    <div className={containerClass}>
      <Document />
    </div>
  );
};
export default Syllabus;
