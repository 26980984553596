import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "material-icons/iconfont/material-icons.css";
import StepContent from "../components/codelab/StepContent";
import Header from "../components/codelab/Header";
import BookCover from "../components/codelab/BookCover";
import firebase from "firebase/compat/app";
import app from "../firebase";
import "firebase/compat/database";
import { getDatabase, onDisconnect, ref, set } from "firebase/database";
import "firebase/firestore";
import { Previewer } from "pagedjs";
import StepList from "../components/codelab/StepList";
import config from "../config";

const Document = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chap = searchParams.get("chap");
  const update = searchParams.get("update");
  const [currentStep, setCurrentStep] = useState(0);
  const [showStepList, setShowStepList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [room, setRoom] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [slideTitle, setSlideTitle] = useState(null);
  const [template, setTemplate] = useState("NEU");
  const isRoomInPath = window.location.pathname.includes("room");
  const isSyllabusInPath = window.location.pathname.includes("syllabus");
  const display = searchParams.get("display") || "doc";

  const [stepsData, setStepsData] = useState({
    steps: [],
    contents: [],
    listChapter: [],
  });

  const onDocClick = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("display", "doc");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const onSlideClick = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("display", "slide");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  const onBookClick = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("display", "book");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleStepClick = (index) => {
    setCurrentStep(index);
    setShowStepList(false);
    window.location.hash = `#${index}`;
    if (room.roomID) {
      const db = getDatabase(app);
      let link = chap ? `/chap${chap}` : "";
      set(
        ref(
          db,
          `/labs/${room.docID.replace(/\./g, "")}/${room.roomID}${link}/users/${user.uid
          }`
        ),
        {
          step: index,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          name: user.displayName,
        }
      );
    }
  };

  const handleTitleClick = () => {
    handleStepClick(0);
  };
  const handleOptionClick = (selectedItem) => {
    if (prevChap !== selectedItem) {
      setSelectedItem(selectedItem);
      setLoading(true);
      setCurrentStep(0);
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("chap", selectedItem);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    } else {
      setCurrentStep(0);
    }
  };
  const handleUserLogin = (userData) => {
    setUser(userData);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevChap = usePrevious(chap);

  const fetchDoc = async () => {
    try {
      let url = `${config.API_BASE_URL}/${isRoomInPath ? "room" : "doc"}/${params["*"]
        }${location.search}`;
      let response = await fetch(url);
      let dataResponse = await response.json();
      if (dataResponse.labName) {
        document.title = dataResponse.labName;
        setSlideTitle(dataResponse.labName);
      }
      setRoom(dataResponse);
      let labConfig = dataResponse.config;
      let listChapter = dataResponse.listChapter;
      if (isRoomInPath) {
        //In room
        if (update === "true" && dataResponse.docID) {
          navigate(`/doc/${dataResponse.docID}`);
          return;
        }
        labConfig = dataResponse.config;
        dataResponse.teacher = dataResponse.userID === user.uid;

        if (labConfig?.template) setTemplate(labConfig?.template);
        const db = getDatabase(app);
        let link = chap ? `/chap${chap}` : "";
        const refUsers = ref(
          db,
          `/labs/${dataResponse.docID.replace(/\./g, "")}/${dataResponse.roomID
          }${link}/users/${user.uid}`
        );
        await onDisconnect(refUsers).set({});
        const hash = window.location.hash;
        const hashIndex = hash ? parseInt(hash.substring(1), 10) : 0;
        //Vao phong, dang ky step
        if (hashIndex > 0) {
          let link = chap ? `/chap${chap}` : "";
          set(
            ref(
              db,
              `/labs/${dataResponse.docID.replace(/\./g, "")}/${dataResponse.roomID
              }${link}/users/${user.uid}`
            ),
            {
              step: hashIndex,
              timestamp: firebase.database.ServerValue.TIMESTAMP,
              name: user.displayName,
            }
          );
        }

        const handleChapChange = async () => {
          if (isRoomInPath && room && user) {
            const db = getDatabase(app);
            const prevChapRef = ref(
              db,
              `/labs/${dataResponse.docID.replace(/\./g, "")}/${dataResponse.roomID
              }/chap${prevChap}/users/${user.uid}`
            );
            await set(prevChapRef, null); // Set the data to null to clear it
          }
        };
        if (prevChap !== chap) {
          await handleChapChange();
        }
      }
      let steps = [];
      let contents = [];
      let currentHeading = {
        name: dataResponse?.labName || "",
        content: [],
        style: "Start",
        level: 0,
        index: 0,
        parentIndex: null,
      };
      const pushCurrentHeading = () => {
        if (
          (currentHeading.style && currentHeading.name?.trim() !== "") ||
          currentHeading.style === "Start"
        ) {
          steps.push({
            style: currentHeading.style,
            name: currentHeading.name?.trim(),
            level: currentHeading.level,
            index: currentHeading.index,
            parentIndex: currentHeading.parentIndex,
            chapter: currentHeading.chapter,
          });
          contents.push({
            content: currentHeading.content,
            level: currentHeading.level,
            index: currentHeading.index,
            parentIndex: currentHeading.parentIndex,
          });
        }
      };
      let lastH1Index = -1;
      let lastH2Index = -1;
      let chapter = 0;
      dataResponse.data?.content.forEach((item, index) => {
        if (item.paragraph) {
          const { namedStyleType } = item.paragraph.paragraphStyle;
          const isValidHeading =
            namedStyleType === "HEADING_1" ||
            namedStyleType === "HEADING_1" ||
            namedStyleType === "HEADING_3";

          if (isValidHeading) {
            pushCurrentHeading();
            currentHeading = {
              name: item.paragraph.elements[0]?.textRun?.content || "",
              content: [],
              style: item.paragraph.paragraphStyle,
              level:
                namedStyleType === "HEADING_1"
                  ? 1
                  : namedStyleType === "HEADING_2"
                    ? 2
                    : 3,
              index: index,
              parentIndex:
                namedStyleType === "HEADING_1"
                  ? -1
                  : namedStyleType === "HEADING_2"
                    ? lastH1Index
                    : lastH2Index,
              chapter: namedStyleType === "HEADING_1" ? ++chapter : chapter,
            };

            if (namedStyleType === "HEADING_1") lastH1Index = index;
            if (namedStyleType === "HEADING_2") lastH2Index = index;
          } else if (namedStyleType === "TITLE") {
            pushCurrentHeading();
            currentHeading = {
              name: item.paragraph.elements[0]?.textRun?.content || "",
              content: [],
              style: item.paragraph.paragraphStyle,
              level: 0,
              index: index,
              parentIndex: -1,
              chapter: 0,
            };
          } else if (item.paragraph.paragraphStyle.className === "H1") {
            pushCurrentHeading();
            currentHeading = {
              name: item.paragraph.elements[0]?.textRun?.content || "",
              content: [],
              style: item.paragraph.paragraphStyle,
              level: 1,
              index: index,
              parentIndex: -1,
              chapter: 0,
            };
          } else {
            currentHeading.content.push(item);
          }
        } else if (item) {
          currentHeading.content.push(item);
        }
      });
      pushCurrentHeading();
      setStepsData({ steps, contents, listChapter });
      setLoading(false);
      if (!isRoomInPath) {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("update", "false");
        navigate(`${location.pathname}?${queryParams.toString()}`, {
          replace: true,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isRoomInPath) {
      if (!user) {
        setLoading(!user);
        return;
      }
    }
    fetchDoc();
  }, [user, selectedItem, chap, prevChap, isRoomInPath]);

  useEffect(() => {
    if (!loading) {
      const hashIndex = parseInt(window.location.hash.substring(1), 10);
      if (
        !isNaN(hashIndex) &&
        hashIndex > 0 &&
        hashIndex <= stepsData.steps.length
      ) {
        setCurrentStep(hashIndex);
      }
    }
  }, [stepsData]);

  useEffect(() => {
    if (!loading) {
      if (display === "book") {
        let paged = new Previewer();
        let DOMContent = document.querySelector(".main-content");
        paged
          .preview(DOMContent, ["../css/book.css"], document.body)
          .then(() => { });
      } else {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = `/css/${display}.css`;
        link.id = "dynamic-css";

        const existingLink = document.getElementById("dynamic-css");
        if (existingLink) {
          document.head.removeChild(existingLink);
        }
        document.head.appendChild(link);
      }
    }
  }, [loading, display]);
  let mainContent;
  if (display != null && (display === "book" || display === "doc")) {
    //Hiển thị toàn bộ nội dung
    mainContent = stepsData.contents.map((content, index) => (
      <div className="container">
        <StepContent
          room={room}
          steps={stepsData.steps}
          step={stepsData.steps[index]}
          content={content}
          currentStep={index}
          display={display}
        />
      </div>
    ));
  } else {
    mainContent = (
      <StepContent
        room={room}
        steps={stepsData.steps}
        step={stepsData.steps[currentStep]}
        content={stepsData.contents[currentStep]}
        currentStep={currentStep}
        display={display}
      />
    );
  }

  return display.includes("book") ? (
    <div className="print">
      {display.includes("book") && <BookCover />}
      {mainContent}
    </div>
  ) : (
    <div className={`${template}`}>
      {(display.includes("slide") || display.includes("doc")) && !isSyllabusInPath && (
        <Header
          stepsData={stepsData}
          currentStep={currentStep}
          handleStepClick={handleStepClick}
          showStepList={showStepList}
          onOptionClick={handleOptionClick}
          slideTitle={slideTitle}
          onDocClick={onDocClick}
          onSlideClick={onSlideClick}
          onBookClick={onBookClick}
          room={room}
          chap={chap}
          onUserLogin={handleUserLogin}
          loading={loading}
          onTitleClick={handleTitleClick}
          display={display}
        />
      )}

      <div className="fluid-container">
        <div className="row">
          <div
            className="offcanvas offcanvas-start d-xxl-none"
            tabindex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                Nội dung
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body p-0">
              <StepList
                steps={stepsData.steps}
                currentStep={currentStep}
                handleStepClick={handleStepClick}
                show={showStepList}
                display={display}
                room={room}
                user={user}
              />
            </div>
          </div>

          {display?.includes("slide") && (
            <div className="sidebar d-none d-xxl-block">
              <StepList
                steps={stepsData.steps}
                currentStep={currentStep}
                handleStepClick={handleStepClick}
                show={showStepList}
                display={display}
                room={room}
                user={user}
              />
            </div>
          )}

          <div
            className={`${display?.includes("slide") ? "col" : "main-content"}`}
          >
            {loading ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border text-primary m-5"
                  role="status"
                ></div>
              </div>
            ) : (
              room && (
                <div className={`justify-content-center content`}>
                  {mainContent}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Document;
