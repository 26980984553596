import React, { useEffect, useState } from "react";
import "./CurriculumCourse.css";
import { useParams, useOutletContext } from "react-router-dom";

const Schools = () => {
  const [majorsList, setMajorsList] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState({});
  const { slugSchool } = useParams();
  const { data } = useOutletContext();
  const [curriculumData, setCurriculumData] = useState([]);
  useEffect(() => {
    const container = document.querySelector(".container");
    container.classList.add("fade-in");
    setCurriculumData(data);
    if (data.length > 0) {
      const finalSchool = data.find(
        (item) => item.attributes.slug === slugSchool
      );
      setSelectedSchool(finalSchool);
    }
  }, [slugSchool, data]);

  useEffect(() => {
    if (selectedSchool?.attributes?.curriculum_majors?.data) {
      const allMajors = selectedSchool.attributes.curriculum_majors.data.map(
        (major) => {
          return {
            name: major?.attributes?.name,
            majorCode: major?.attributes?.majorCode,
            admissionCode: major?.attributes?.admissionCode,
            credits: major?.attributes?.credits || "130",
            slug: major?.attributes?.slug,
          };
        }
      );
      setMajorsList(allMajors);
    }
  }, [selectedSchool]);
  return (
    <div>
      <div className="container my-5 flex-grow-1" style={{ minHeight: "60vh" }}>
        <h1 className="d-flex justify-content-center mt-4 mb-4 header">
          {selectedSchool?.attributes?.name}
        </h1>
        <p
          className="mb-4 description"
          dangerouslySetInnerHTML={{
            __html: selectedSchool?.attributes?.description,
          }}
        ></p>
        <div className="row mt-4">
          <strong>
            Website :{" "}
            <a href={selectedSchool?.attributes?.website} target="_blank">
              {selectedSchool?.attributes?.website}
            </a>
          </strong>
          <strong>
            Địa chỉ : <span>{selectedSchool?.attributes?.address}</span>
          </strong>{" "}
        </div>
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-9">
            <table className="table table-striped table-bordered text-center table-hover custom-table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">Mã ngành</th>
                  <th scope="col">Mã tuyển sinh</th>
                  <th scope="col">Tên ngành</th>
                  <th scope="col">Số tín chỉ</th>
                </tr>
              </thead>
              <tbody>
                {majorsList.map((major, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{major?.majorCode}</td> <td>{major?.admissionCode}</td>
                    <td>
                      <a href="/curriculum/74801101" className="course-link">
                        {major?.name}
                      </a>
                    </td>
                    <td>{major?.credits}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schools;
