// Quiz.js
import React, { useState } from "react";
import { SHA256 } from "crypto-js";
import ParagraphContent from "./ParagraphContent";
import StepBody from "./StepBody";
import { readElements } from "../../utils/codelab";

const generateQuizId = (content) => {
  return SHA256(content).toString().substring(0, 16);
};

const Quiz = ({ table }) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const handleAnswerChange = (index) => {
    setSelectedAnswerIndex(index);
  };
  if (
    table &&
    table.tableRows.length >= 2 &&
    table.tableRows[0].tableCells.length >= 1
  ) {
    let result = "";
    if (table && table.tableRows) {
      table.tableRows.forEach((row) => {
        result += readElements(row.tableCells[0].content);
      });
    }
    const quizId = generateQuizId(result);
    const question = table.tableRows[0].tableCells[0].content.map(
      (paragraph) => (
        <div>
          <ParagraphContent key={`${quizId}`} content={paragraph} />
        </div>
      )
    );

    const answers = table.tableRows.slice(1).map((row, index) => {
      if (row.tableCells.length >= 1) {
        const optionText = row.tableCells[0].content.map((paragraph) => (
          <ParagraphContent content={paragraph} />
        ));
        const optionId = `${quizId}_${index}`;
        return (
          <div className="quiz-choice">
            <input
              className="form-check-input me-3"
              type="radio"
              id={optionId}
              checked={selectedAnswerIndex === index}
              onChange={() => handleAnswerChange(index)}
            />
            <label htmlFor={optionId}>{optionText}</label>
          </div>
        );
      }
      return null;
    });

    return (
      <div className="quiz" id={quizId}>
        <div>{question}</div>
        <div>
          <form>{answers}</form>
        </div>
      </div>
    );
  }
  return null;
};

export default Quiz;
