// utils.js
import { getDatabase, onDisconnect, ref, set } from "firebase/database";
import firebase from "firebase/compat/app";
import app from "../firebase";

export function readElements(elements) {
  let result = "";
  if (elements)
    elements.forEach((element) => {
      if (element.paragraph) {
        element.paragraph.elements.forEach((paragraphElement) => {
          result += readParagraphElement(paragraphElement);
        });
      } else if (element.table) {
        element.table.tableRows.forEach((row) => {
          row.tableCells.forEach((cell) => {
            result += readElements(cell.content);
          });
        });
      }
    });
  return result;
}

export function readParagraphElement(element) {
  const run = element.textRun;
  if (!run || !run.content) {
    return "";
  }
  return run.content;
}

export function removeNumbering(text) {
  return text.replace(/^\d+(\.\d+)*\.\s*/, "");
}



export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result;
}
