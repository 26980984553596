import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useOutletContext } from "react-router-dom";

const FacultyCard = ({ faculty }) => (
  <div className="col-md-6 col-lg-4 mb-4">
    <NavLink to={`/curriculum/school/${faculty?.attributes.slug}`}>
      <div className="card h-100 border-0 border-top border-primary rounded-0 border-4 shadow">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start mb-2">
            <h5
              className="card-title font-weight-bold mb-0 flex-grow-1"
              style={{ overflowWrap: "break-word", whiteSpace: "normal" }}
            >
              {faculty?.attributes.name}
            </h5>
            <span className="ml-2" style={{ fontSize: "1.5rem" }}>
              &rarr;
            </span>
          </div>
          <p
            className="card-text"
            style={{
              overflowWrap: "break-word",
              whiteSpace: "normal",
              marginBottom: 0,
            }}
          >
            {faculty.attributes.description}
          </p>
        </div>
      </div>
    </NavLink>
  </div>
);

const SchoolCard = ({ school }) => (
  <div className="col-12 col-md-6 col-lg-4 mb-4">
    <NavLink to={`/curriculum/${school?.slug}`}>
      <div className="card h-100 border-0 border-top border-primary rounded-0 border-4 shadow">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start mb-2">
            <h5
              className="card-title font-weight-bold mb-0 flex-grow-1"
              style={{ overflowWrap: "break-word", whiteSpace: "normal" }}
            >
              {school.name}
            </h5>
            <span className="ml-2" style={{ fontSize: "1.5rem" }}>
              &rarr;
            </span>
          </div>
          <p
            className="card-text"
            style={{
              overflowWrap: "break-word",
              whiteSpace: "normal",
              marginBottom: 0,
            }}
            dangerouslySetInnerHTML={{ __html: school.description }}
          ></p>
        </div>
      </div>
    </NavLink>
  </div>
);

const Browse = (props) => {
  const [view, setView] = useState("school");
  const { data, dataFalcuties } = useOutletContext();
  const [schoolList, setSchoolList] = useState([]);
  const [majorList, setMajorList] = useState([]);
  const [falcutyList, setFalcutyList] = useState([]);
  console.log(data);
  useEffect(() => {
    if (data?.length > 0) {
      const schools = data.map((item) => {
        return {
          name: item.attributes.name,
          description: item.attributes.description,
          slug: item.attributes.slug,
          curriculum_faculties: item.attributes.curriculum_faculties,
          curriculum_majors: item.attributes.curriculum_majors,
        };
      });
      const majors = data.map((item) => {
        return {
          curriculum_majors: item.attributes.curriculum_majors,
        };
      });
      setSchoolList(schools);
      setMajorList(majors);
      setFalcutyList(dataFalcuties);
    }
    const savedView = localStorage.getItem("view");
    if (savedView) {
      setView(savedView);
    }
  }, [data, dataFalcuties]);

  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem("view", newView);
  };

  return (
    <div>
      <div className="container my-5 flex-grow-1" style={{ minHeight: "60vh" }}>
        <h2 className="mb-4">Tra Cứu</h2>
        <div className="d-flex gap-3 mb-4">
          <button
            className={`btn btn-link ${
              view === "school"
                ? "border-0 border-bottom border-primary rounded-0 border-3"
                : ""
            }`}
            onClick={() => handleViewChange("school")}
          >
            Theo Trường
          </button>

          <button
            className={`btn btn-link p-0 ${
              view === "faculty"
                ? "border-0 border-bottom border-primary rounded-0 border-3"
                : ""
            }`}
            onClick={() => handleViewChange("faculty")}
          >
            Theo Khoa/Viện
          </button>
        </div>
        <div className="row">
          {view === "faculty"
            ? falcutyList.map((falcuty, index) => (
                <FacultyCard
                  faculty={falcuty} // Correcting the variable name to match
                  key={`${falcuty.name}-${index}`} // Using a consistent and unique key
                />
              ))
            : schoolList.map((school, index) => (
                <SchoolCard school={school} key={index} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default Browse;
