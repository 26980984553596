import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { LanguageOutlined, Menu } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import LogoNEU from "../LogoNEU.png";
import "./header.css";
const HeaderCurriculum = (props) => {
  const [curriculumData, setCurriculumData] = useState(props.dataSchools);
  const [schoolList, setSchoolList] = useState([]);
  const [majorList, setMajorList] = useState([]);
  const [falcutyList, setFalcutyList] = useState([]);
  useEffect(() => {
    setCurriculumData(props.dataSchools);
    if (props.dataSchools.length > 0) {
      const schools = props.dataSchools.map((item) => {
        return {
          name: item.attributes.name,
          description: item.attributes.description,
          slug: item.attributes.slug,
          curriculum_faculties: item.attributes.curriculum_faculties,
          curriculum_majors: item.attributes.curriculum_majors,
        };
      });
      const majors = props.dataSchools.map((item) => {
        return {
          curriculum_majors: item.attributes.curriculum_majors,
        };
      });
      setSchoolList(schools);
      setMajorList(majors);
      setFalcutyList(props.dataFalcuties);
    }
  }, [props.dataSchools, props.dataFalcuties]);
  console.log(props.dataFalcuties);
  return (
    <nav
      className="navbar navbar-expand-lg p-4 mb-4"
      id="navbar-header"
      style={{ backgroundColor: "#085ca7" }}
    >
      <div className="container-fluid">
        <div className="name-university col-xl-4 col-lg-3 col-sm-12 col-md-5 col-10 d-flex justify-content-start ">
          <NavLink
            to="/curriculum/browse"
            className="navbar-brand d-flex align-items-center text-white"
          >
            <img
              src={LogoNEU}
              alt="LogoNeu"
              width="60px"
              height="60px"
              className="img-logo"
            />
            <div className="d-flex flex-column ms-2">
              <h1 className="m-0 text-white font-bold fs-4 course-title">
                Trường Đại Học Kinh Tế Quốc Dân
              </h1>
              <h2 className="m-0 text-light fs-6 course-title">
                Chương trình đào tạo
              </h2>
            </div>
          </NavLink>
        </div>
        {/* Handle the responsive button  */}
        <button
          className="navbar-toggler d-flex justify-content-center align-items-center col-2"
          type="button"
          id="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{
            color: "white" /* Ensures text is black */,
            border: "none" /* Removes any border */,
            boxShadow: "none",
            width: "40px",
          }}
        >
          <Menu />
        </button>
        <div className="collapse navbar-collapse d-none" id="navbarCollapse">
          <ul className="navbar-nav ms-auto p-4 p-lg-0">
            {/* {MenuItems(menuItems)} */}
            <li className="nav-item dropdown me-3 text-white">
              <a
                className="nav-link dropdown-toggle text-white fs-6 fw-bold"
                href="#"
                id="schoolsDropdownMobile"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Theo trường
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="schoolsDropdownMobile"
              >
                {schoolList.map((school, index) => (
                  <NavLink
                    to={`/curriculum/${school.slug}`}
                    className="dropdown-item-list"
                  >
                    <li key={index}>
                      <a className="dropdown-item">{school.name}</a>
                    </li>
                  </NavLink>
                ))}
              </ul>
            </li>
            <li className="nav-item dropdown text-white">
              <a
                className="nav-link dropdown-toggle text-white fs-6 fw-bold"
                href="#"
                id="facultiesDropdownMobile"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Theo khoa/Viện
              </a>
              <ul
                className="dropdown-menu dropdown-menu-faculty"
                aria-labelledby="facultiesDropdownMobile"
              >
                {falcutyList.map((faculty, index) => (
                  <NavLink to={`/curriculum/school/${faculty.attributes.slug}`}>
                    <li key={index}>
                      <a className="dropdown-item">{faculty.attributes.name}</a>
                    </li>
                  </NavLink>
                ))}
              </ul>
            </li>
          </ul>
        </div>

        <div className="d-flex col-xl-4 col-lg-5 col-sm-0 col-md-6 justify-content-center align-items-center">
          <input
            type="search"
            id="form1"
            className="form-control me-2 rounded-0"
            placeholder="Tìm kiếm tên ngành ..."
          />
          <div className="dropdown ms-2">
            <LanguageOutlined
              sx={{ color: "white", cursor: "pointer" }}
              className="dropdown-toggle"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="languageDropdown"
            >
              <li>
                <a className="dropdown-item" href="#">
                  Tiếng việt
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  English
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xl-3 col-lg-0 col-md-0  d-flex ">
          <ul className="navbar-nav" style={{ marginLeft: "20px" }}>
            <li className="nav-item dropdown me-3 text-white">
              <a
                className="nav-link dropdown-toggle text-white fs-6 fw-bold"
                href="#"
                id="schoolsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Theo trường
              </a>
              <ul className="dropdown-menu " aria-labelledby="schoolsDropdown">
                {schoolList.map((school, index) => (
                  <NavLink to={`/curriculum/${school.slug}`}>
                    <li key={index}>
                      <a className="dropdown-item">{school.name}</a>
                    </li>
                  </NavLink>
                ))}
              </ul>
            </li>

            <li className="nav-item dropdown text-white">
              <a
                className="nav-link dropdown-toggle text-white fs-6 fw-bold"
                href="#"
                id="facultiesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Theo khoa/Viện
              </a>
              <ul
                className="dropdown-menu dropdown-menu-faculty"
                aria-labelledby="facultiesDropdown"
                style={{ position: "absolute", left: "-139px" }}
              >
                {falcutyList.map((faculty, index) => (
                  <NavLink to={`/curriculum/school/${faculty.attributes.slug}`}>
                    <li key={index}>
                      <a className="dropdown-item">{faculty.attributes.name}</a>
                    </li>
                  </NavLink>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeaderCurriculum;
