import React, {useEffect} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import ExcelExport from "../components/SummaryPosts/ExcelExport";
import slugify from "slugify";

export default function SummaryPosts() {
  const [posts, setPosts] = React.useState([]);
  const [month, setMonth] = React.useState(moment().month() + 1);

  const processDataExport = (posts) => {
    return (posts??[]).map((post, index) => ({
      "TT": index + 1,
      "Ngày đăng": moment(post?.publishedAt).format("DD/MM/YYYY"),
      "Link bài viết": `https://fit.neu.edu.vn/post/${post.slug}`,
      "Tiêu chí số": "",
      "Số điểm": ""
    }))
  }

  const getFilterString = (month) => {
    const year = moment().year();
    const start = moment(`${year}-${month}-01`).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    const end = moment(`${year}-${month}-${moment(`${year}-${month}`, "YYYY-MM").daysInMonth()}`).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    return `https://fit.neu.edu.vn/admin/api/blogs?filters[publishedAt][$gte]=${start}&filters[publishedAt][$lte]=${end}&sort=publishedAt:asc&populate=*`;
  }

  useEffect(() => {
    fetch(getFilterString(month))
      .then(res => res.json())
      .then(res => {
        const final = (res?.data??[]).map((item, index) => {
          return {
            id: item.id,
            ...item.attributes
          }
        });
        setPosts(final);
      })
  }, [month]);

  return (
    <>
      <div className="container min-vh-100">
        <h2 className="text-center mt-5 mb-3" style={{color: "#0054a2", fontWeight: "650"}}>TỔNG KẾT BÀI ĐĂNG NĂM {moment().year()}</h2>
        <div className={"mb-2 d-flex align-items-center gap-1 justify-content-end"}>
          <select
            className="form-select w-25"
            aria-label="Select month"
            onChange={(e) => setMonth(e.target.value)}
            value={month}
          >
            <option selected>Chọn tháng</option>
            <option value="1">Tháng 1</option>
            <option value="2">Tháng 2</option>
            <option value="3">Tháng 3</option>
            <option value="4">Tháng 4</option>
            <option value="5">Tháng 5</option>
            <option value="6">Tháng 6</option>
            <option value="7">Tháng 7</option>
            <option value="8">Tháng 8</option>
            <option value="9">Tháng 9</option>
            <option value="10">Tháng 10</option>
            <option value="11">Tháng 11</option>
            <option value="12">Tháng 12</option>
          </select>
          <ExcelExport data={processDataExport(posts)} fileName={`Tổng kết bài đăng tháng ${month}/${moment().year()}`} />
        </div>
        <table className="table table-striped table-bordered table-hover table-sm"
               style={{borderCollapse: 'collapse', border: '1px solid black'}}>
          <thead>
          <tr className="text-center align-middle"
              style={{backgroundColor: '#f8f9fa', fontWeight: 'bold', border: '1px solid black'}}>
            <th style={{fontWeight: "bold", textAlign: "center", border: '1px solid black'}}>TT</th>
            <th style={{fontWeight: "bold", textAlign: "center", border: '1px solid black'}}>Bài viết</th>
            <th style={{fontWeight: "bold", textAlign: "center", border: '1px solid black'}}>Phân loại</th>
            <th style={{fontWeight: "bold", textAlign: "center", border: '1px solid black'}}>Người đăng</th>
            <th style={{fontWeight: "bold", textAlign: "center", border: '1px solid black'}}>Ngày đăng</th>
          </tr>
          </thead>
          <tbody>
          {posts.length > 0 ? posts?.map((post, index) => {
            return (
              <tr key={post.id} className="text-center align-middle"
                  style={{border: '1px solid black'}}>
                <td style={{border: '1px solid black'}}>{index + 1}</td>
                <td style={{border: '1px solid black'}}>
                  <Link to={`/post/${post.slug}`} target="_blank">{post?.title}</Link>
                </td>
                <td style={{border: '1px solid black'}}>
                  <Link to={`/news/category/${post?.blog_category?.data?.attributes?.slug}`}
                        target="_blank">{post?.blog_category?.data?.attributes?.title}</Link>
                </td>
                <td style={{border: '1px solid black'}}>
                  <Link to={`/news/author/${slugify(`${post?.createdBy?.data?.attributes?.firstname} ${post?.createdBy?.data?.attributes?.lastname} ${post?.createdBy?.data?.id}`, {lower: true, locale: 'vi'})}`}
                        target="_blank">{`${post?.createdBy?.data?.attributes?.firstname} ${post?.createdBy?.data?.attributes?.lastname}`}</Link>
                </td>
                <td
                  style={{border: '1px solid black'}}>{moment(post?.publishedAt).format("DD [tháng] MM YYYY, HH:mm")}</td>
              </tr>
            );
          }) : (
            <tr>
              <td colSpan={3} className="text-center">Không có bài viết nào</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </>
  );
}