import React, { useState, useEffect } from "react";

function Footer() {
  const [bulletMenu, setBulletMenu] = useState([]); // State cho menu dạng bullet-list
  const [rowMenu, setRowMenu] = useState([]); // State cho menu theo hàng ngang

  useEffect(() => {
    fetch("https://fit.neu.edu.vn/admin/api/navigation/render/2?type=TREE")
      .then((response) => response.json())
      .then((data) => {
        if (data.length>0){
          setBulletMenu(data);
        }
        
      })
      .catch((error) => {
        console.error("Error fetching bullet-menu data:", error);
      });

    fetch("https://fit.neu.edu.vn/admin/api/navigation/render/3?type=TREE")
      .then((response) => response.json())
      .then((data) => {
        setRowMenu(data);
      })
      .catch((error) => {
        console.error("Error fetching row-menu data:", error);
      });
  }, []);

  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
        {bulletMenu.length > 0 && (
            <>
              {/* Menu Địa chỉ */}
              <div className="col-lg-6">
                <h4 className="text-light fw-bold mb-4">{bulletMenu[0].title}</h4>
                {bulletMenu[0].items.map((item) => (
                  <p className="mb-2" key={item.id}>
                    {/* Icon tự động theo kiểu thông tin */}
                    { /\d{7,}/.test(item.title) ? (   // Nếu chuỗi item.title chứa ít nhất 7 chữ liên tục -> sđt
                      <i className="fa fa-phone-alt me-3"></i>
                    ) : item.title.includes("@") ? (  // Nếu chuỗi item.title chứa kí tự '@' -> email
                      <i className="fa fa-envelope me-3"></i>
                    ) : (
                      <i className="fa fa-map-marker-alt me-3"></i>
                    )}
                    {item.title}
                  </p>
                ))}

                {/* Mạng xã hội */}
                <div className="d-flex pt-2">
                  <a className="btn btn-outline-light btn-social" href="">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="btn btn-outline-light btn-social" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="btn btn-outline-light btn-social" href="">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a className="btn btn-outline-light btn-social" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>

              {/* Menu Thông tin */}
              <div className="col-lg-6">
                <h4 className="text-light fw-bold mb-4">{bulletMenu[1].title}</h4>
                {bulletMenu[1].items.map((item) => (
                  <a key={item.id} className="btn btn-link" href={item.path}>
                    {item.title}
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              Bản quyền thuộc về&nbsp;
              <a className="border-bottom" href="/">
                Khoa Công nghệ thông tin
              </a>
              &nbsp; &copy;
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                {rowMenu.map((item) => (
                  <a key={item.id} href={item.path}>
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;