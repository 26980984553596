import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetch from "../utils/fetch";
import config from "../utils/config";
import "../css/lectureList.css";
import Spinner from "../containers/Spinner";
const LecturerList = () => {
  document.title = "Giảng viên - Cán bộ | Khoa Công nghệ thông tin";

  const { data, error, loading } = useFetch(
    `${config.API_URL}/api/lecturers?populate=*`
  );
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  // Sort data by index in ascending order, placing null values at the end
  const sortedData = data?.data?.slice().sort((a, b) => {
    const indexA = a.attributes.index;
    const indexB = b.attributes.index;

    if (indexA === null) return 1;
    if (indexB === null) return -1;
    return indexA - indexB;
  });

  const totalItems = sortedData?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = sortedData?.slice(startIndex, endIndex);

  return (
    <div className="lecturer-list">
      <div className="container py-5">
        <h1 className="text-center mb-5 text-primary bold">
          Giảng viên - cán bộ
        </h1>

        <div className="row">
          {itemsToDisplay.map((lecturer) => (
            <div
              key={lecturer.id}
              className="col-sm-12 col-md-6 col-lg-4 col-xl-3 wow fadeInUp mb-4"
            >
              <div className="card h-100 text-center">
                <Link to={`/lecturer/${lecturer.attributes.slug}`}>
                  <div className="content-overlay"></div>
                  <img
                    className="card-img-top lecturer-img"
                    src={`https://fit.neu.edu.vn/admin${lecturer.attributes.avatarNew.data.attributes.url}`}
                    alt={lecturer.attributes.displayName}
                    style={{ cursor: "pointer" }}
                  />
                </Link>
                <div className="card-body">
                  <Link
                    to={`/lecturer/${lecturer.attributes.slug}`}
                    className="lecturer-name-link"
                  >
                    <h5
                      className="card-title tenGv"
                      style={{ cursor: "pointer" }}
                    >
                      {lecturer.attributes.displayName}
                    </h5>
                  </Link>
                  <p className="card-text">{lecturer.attributes.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <nav>
          <ul className="pagination justify-content-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <li
                key={index}
                className={`page-item ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handleClick(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default LecturerList;
